<template>
  <div class="out-back-demo">
      <div v-if="isEmpty == false" v-for="(item, index) in CustomList" :key="index">
        <Custom @customMusic="customMusic" :CustomList="item" type="complete"></Custom>
      </div>
	  <div v-if="isEmpty == true" class="emptyView">
	  	<el-empty :image-size="100"></el-empty>
	  </div>
	  <CustomPopup ref="customPopup" :customMusic="customMusicData"></CustomPopup>
  </div>
</template>
    
<script>
import Custom from "@/components/content/Custom.vue"
import CustomPopup from "@/components/popup/CustomPopup.vue"
import { customMusicList } from "@/api/Customization"
export default {
  props: {},
  data() {
    return {
		customMusicData: {},
		loading: true,
		isEmpty: false,
      CustomList: [{
        music_title: '英雄联盟-黑暗时刻',
        end_time: '2021-08-22',
        release_time: '2021-08-21',
        custom_code: '350967',
        img: 'https://gimg3.baidu.com/yule/src=http%3A%2F%2Fgips2.baidu.com%2Fit%2Fu%3D1715359177%2C946060353%26fm%3D3007%26app%3D3007%26f%3DJPEG%3Fw%3D480%26h%3D480&refer=http%3A%2F%2Fwww.baidu.com&app=2019&size=w931&n=0&g=0n&q=75&fmt=auto?sec=1667667600&t=98df8a00e53f012ac94086e90e7e9860',
      },
      {
        music_title: '英雄联盟-黑暗时刻',
        end_time: '2021-08-22',
        release_time: '2021-08-21',
        custom_code: '350967',
        img: 'https://gimg3.baidu.com/yule/src=http%3A%2F%2Fgips2.baidu.com%2Fit%2Fu%3D1715359177%2C946060353%26fm%3D3007%26app%3D3007%26f%3DJPEG%3Fw%3D480%26h%3D480&refer=http%3A%2F%2Fwww.baidu.com&app=2019&size=w931&n=0&g=0n&q=75&fmt=auto?sec=1667667600&t=98df8a00e53f012ac94086e90e7e9860',


      }]

    }
  },
  components: { Custom,CustomPopup },
  computed: {},
  created() {
  },
  mounted() {
	  customMusicList().then(res=>{
	      this.CustomList = res.data.list
	  		  this.loading = false
	  		  if(this.CustomList == ''){
	  		  	this.isEmpty = true
	  		  }else {
	  		  	this.isEmpty = false
	  		  }
	      // console.log(res,'customLog');
	  })
  },
  watch: {},
  methods: {
	customMusic(data){
		// console.log(data,'customMusic');
		this.customMusicData = data;
		this.$refs.customPopup.open();
	}
  }
}
</script>
    
<style scoped lang='less'>
	/deep/ .el-loading-mask{
		background-color: rgba(0,0,0,0);
	}
.out-back-demo {
  width: 100%;
  height: 850px;
  .emptyView{
  	width: 100%;
  	display: flex;
  	justify-content: center;
  	margin-top: 30vh;
  }
}
</style>
    